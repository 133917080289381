import React, { useRef, useState,  } from "react";

// react boostrap imports
import { Form, Button, Alert, Container, Row, Col } from "react-bootstrap";

// auth imports
import { useAuth } from "../../Components/Context/AuthContext";
// react router dom imports
import { Link, useNavigate } from "react-router-dom";
import "../../index.css";
// mailes imports

import { verifiedEmails } from "../../email";
import { PaystackButton } from "react-paystack";
import { H4LoginBlue } from "../../Components/Elements/PagesElement";
import { Hr } from "../../Components/Elements/DashboardElemtents";


const NotVerifiedPage = () => {
  const donationAmountRef = useRef(0)
  const {logout,verifyUser,currentUser} = useAuth()
  const [disabled ,setdisabled] = useState(false)
  const [error, setError] = useState("");
  const [donationAmount, setDonationAmount] = useState(50);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);


  const clickbutton =()=>{
    verifyUser()
    setdisabled(true)
  }

     // PAYSTACK INTEGRATION

  // SUCCESSFULLY PAID
  const handlePaystackSuccessAction = async(reference) => {
    setLoading(true)
    setError(error);
    setSuccess(true);
    setLoading(false)
    verifyUser()
    console.log(reference);

  };

  // you can call this function anything
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const config = {
    reference: (new Date()).getTime().toString(),
    currency: "GHS",
    email: currentUser.email,
    amount: 100* ( donationAmount),
    // publicKey: "pk_test_9d8588032afcf9c4523f2b19fb280cdca68d96fd",
    publicKey: "pk_live_409801cc9dd0519e7209890bd86c73c99f679bd4",
  };

  const componentProps = {
    ...config,
    text: "Donate GHS"+ ( donationAmount),
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: () => handlePaystackCloseAction(),

  };
  // END OF PAYSTACK INTEGRATION


  return (
    <Container fluid>
      <Row>
          <Container fluid className="my-auto">
            <Row className="height-full">

            <Col md={12} className="mx-auto my-auto text-center">
            

            {!success && ((verifiedEmails.includes(currentUser.email))  ?
            <Row className="">
              <H4LoginBlue className="header p-1 text-success">Congratulations!!!</H4LoginBlue>
              <h4 className="header p-1 ">But... you haven't been verified yet!</h4>

             
              <Col  className="text-center ">
                
                   <Button disabled={disabled} onClick={()=>clickbutton()}
                    className="primary-button"
                    >
                   Verify my account
                  </Button>

                  <p>Didn't receive a mail?...wait for 5 minutes to try again.</p>

                    
              </Col>
              </Row> : 
              <Row>
                <Col  className="text-center my-auto">
                <Form.Group className='col-md-4 my-4 mx-auto'>
                  <H4LoginBlue>We Need Your Help!</H4LoginBlue>
                  <p className="Lead">As an NGO , we need your donations to help render the services and suport to the entire ONAM family, we are counting on you!</p>
                    <Form.Label >Amount</Form.Label>
                    <Form.Control
                      className="form-input  p-3 border-0 text-center"
                      type="number"
                      placeholder="Enter any amount"
                      min={50}
                      value={donationAmount}
                      onChange={(e)=>setDonationAmount(e.target.value)}
                      required
                      
                    />
                  </Form.Group>
                  {(donationAmount >= 50) && 
                  <PaystackButton
                            className="primary-button mx-auto px-3"
                            {...componentProps}
                        />}
                  <br/>
                  <span>NB: we need your donation before you can proceed to your portal!</span>
                </Col>
              </Row>

              
              
              
              )
            }
              

              {(success && !loading) &&
               <Row className="">
               <H4LoginBlue className="header p-1 text-success">Congratulations!!!</H4LoginBlue>
               <h4 className="header p-1 ">But... you haven't been verified yet!</h4>
               <h4 className="header p-1 ">Kindly check your email for a verification link from us!</h4>
 
              
               <Col  className="text-center ">
                 
                    <Button disabled={disabled} onClick={()=>clickbutton()}
                     className="primary-button"
                     >
                    Verify my account
                   </Button>
 
                   <p>Didn't receive a mail?...wait for 5 minutes to try again.</p>
 
                     
               </Col>
               </Row> 
                
              }
            </Col>
            </Row>
          </Container>
      </Row>
    </Container>
    
  );
};

export default NotVerifiedPage;
