// import { DropzoneArea } from 'material-ui-dropzone'
import React, { useRef, useState } from 'react'
import { Row ,Form, Col, InputGroup, Container, Breadcrumb, ProgressBar, Image} from 'react-bootstrap'
import { FaCogs,FaCog, FaHammer } from 'react-icons/fa'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../Components/Context/AuthContext'
import { AddressBar, Chartbox, Headbar, Hr, MainDiv, TitleText, WelcomeText } from '../../Components/Elements/DashboardElemtents'
import { ActionLink, ActiveButtonDeepBlue, ActiveButtonGreen, ActiveButtonLightBlue, ActiveButtonRed, H4Light, H4LoginBlue,PrimaryHighlight } from '../../Components/Elements/PagesElement';
import { storage as storageRef } from '../../firebase'

// import "./Dashboard.css";

export default function ResetPassword() {
  const [data, setData] = useState([]);
  const [mainError, setMainError] = useState('');
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(''); // sets Error from operations
  const [formError, setFormError] = useState([]); // check error from forms
  const [success,setSuccess]=useState(false)
  const [loading, setLoading] = useState(false);
  const passwordRef = useRef();
  const NewpasswordRef = useRef();
  const RepeatnewpasswordRef = useRef();
  const [progress, setProgress] = useState(0);
  const [img,setImg] =useState(null);

  const { userID ,ChangePassword} = useAuth()

  const history = useNavigate();
  // const { login } = useAuth();
  let location = useLocation();

  let { from } = location.state || { from: { pathname: "/dashboard" } };




  

  const findFormErrors = () => {
      const newErrors = {}
      // name errors
      if ( !data.session || data.session === '' ) newErrors.name = 'Cannot be blank!'
      else if ( data.session.length > 100 ) newErrors.name = 'session is too long!'
      // food errors
      if ( !data.date || data.date === '' ) newErrors.date = 'Add a valid date!'
      if ( !data.datefororder || data.datefororder === '' ) newErrors.date = 'Add a valid date!'
      if ( !data.minage || data.minage === '' ) newErrors.minage = 'select level!'
  
      

      return newErrors;
  }

  
  const Submit = async ()=>{ 
      // Add Product
      // const {error,success} = await AddClass(data,branch);
      
      setMainError(error);
      setSuccess(success);
      setData('');
      // closeModal()
      setLoading(false);

 }


  
  const Proceed = async (e) => {
      e.preventDefault();
      console.log("initiated password reset")
      try {
        setLoading(true)
          // Conditional logic:

          if(passwordRef.current.value){
            if ( NewpasswordRef.current.value === RepeatnewpasswordRef.current.value ){
              await ChangePassword(passwordRef.current.value,NewpasswordRef.current.value)
              setLoading(false)
              

            }else{
              throw "New Passwords Dont Match!"
            }
          }
              
      } catch (error) {
        setError(error);
        setLoading(false)

      }
  
      
      
  };
  // async function handleSubmit(e) {
  //   e.preventDefault();

   

  //   try {
  //     setError("");
  //     setLoading(true);

  //     //   await login(emailRef.current.value, passwordRef.current.value);
  //       console.log("completed login, going to verify")

  //     //               await verifyUser();
  //     history.push(from);
  //   } catch (error) {
  //     setError(error.message);
  //   }

  //   setLoading(false);
  // }

  return (
    <div className='w-100 m-0'>
    <Headbar className='col-12 m-0 align-top sticky-top m-0 p-0'>
    <Link to={"/dashboard/profiles/edit"} className='float-end align-top my-auto'><FaCog/></Link> 
    <WelcomeText className='align-top'>User Name</WelcomeText>

    
    <Hr></Hr>
    <AddressBar className='p-3'>
        <TitleText className='p-0'>Profile</TitleText>
        <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard" }}>Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard/profiles" }} >
                Profiles
                </Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard/profiles/reset-password" }} active >
                Reset Password
                </Breadcrumb.Item>
            
        </Breadcrumb>
    </AddressBar>
    </Headbar>
    <MainDiv className='row p-3'>
    <div className='text-center my-2'>
                  
          <ActiveButtonLightBlue
          as={Link}
          to="/dashboard/profiles"
              disabled={loading}
              variant="success"
              className="primary-button text-center border-0 px-5 mx-2"
              type="button"
          >   Back
          </ActiveButtonLightBlue>
          <ActiveButtonDeepBlue as={Link} to="/dashboard/profiles/edit"
              disabled={loading}
              variant="success"
              className="primary-button text-center border-0 px-5 mx-2"
              type="button"
          >
              Edit Profile
          </ActiveButtonDeepBlue>
          
      </div>

              <div className='col-12 col-md-8 col-lg-7 '>
                  <Form onSubmit={Proceed} className="row ">
                    <Hr/>
                    <H4LoginBlue>Reset Password</H4LoginBlue>

                    <Form.Group controlId="formBasicPassword1" className='my-4'>
                    <Form.Label >Password</Form.Label>
                      <Form.Control
                        className="form-input  p-3 border-0"
                        type="password"
                        placeholder="Password"
                        ref={passwordRef}
                        
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword2" className='my-4'>
                    <Form.Label >New Password</Form.Label>
                      <Form.Control
                        className="form-input  p-3 border-0"
                        type="password"
                        placeholder="Password"
                        ref={NewpasswordRef}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword3" className='my-4'>
                    <Form.Label >Repeat Password</Form.Label>
                      <Form.Control
                        className="form-input  p-3 border-0"
                        type="password"
                        placeholder="Password"
                        ref={RepeatnewpasswordRef}
                      />
                    </Form.Group>
                    {/* <Row>
                      <Form.Group>
                    <Col className=''>
                      <span className="p-2 mx-3">I am a</span>
                    <Form.Check 
                      className='d-inline-flex mx-3 p-2'
                      type='radio'
                      id='remember'
                      name='role'
                      value="supplier"
                      label={ <PrimaryHighlight className='px-2' >Supplier</PrimaryHighlight>}
                      />
                
                  
                    <Form.Check 
                      className='d-inline-flex mx-3 p-2 '
                      type='radio'
                      id='remember'
                      name='role'
                      value="secretariat"
                      label={ <PrimaryHighlight className='px-2' >Secretariat</PrimaryHighlight>}
                      />
                
                    <Form.Check 
                      className='d-inline-flex mx-3 p-2'
                      type='radio'
                      id='remember'
                      name='role'
                      value="facility_user"
                      label={ <PrimaryHighlight className='px-2' >Facility User</PrimaryHighlight>}
                      />
                    </Col>
                    </Form.Group> */}
                    {/* <Col className='text-end'>
                    <ActionLink to="/reset-password">Forgot Password</ActionLink>

                    </Col>
                    </Row> */}
                    <div className='text-center my-2'>
                      <ActiveButtonGreen
                          disabled={loading}
                          variant="success"
                          className="primary-button text-center border-0 px-5 m-2"
                          type="submit"
                      >
                          Save
                      </ActiveButtonGreen>
                      <ActiveButtonRed as={Link} to="/dashboard/profiles"
                          disabled={loading}
                          variant="success"
                          className="primary-button text-center border-0 px-5 m-2"
                          type="button"
                      >
                          Cancel
                      </ActiveButtonRed>
                    </div>
                    
                  </Form>
              </div>
          
    </MainDiv>
  </div>
  )
}
