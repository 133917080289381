import React,{useRef,useState} from 'react'
import { Row,Container,Form ,Alert,Col,Button, Image, Spinner} from 'react-bootstrap'
import { Link, useLocation, useNavigate,useNavigation } from 'react-router-dom';
import { ActionLink, ActiveButtonDeepBlue, H4LoginBlue } from '../../Components/Elements/PagesElement';
import "./auth-pages.css";
import africanimg from "../../Extra/images/150-logo-black.png"
import { useAuth } from '../../Components/Context/AuthContext';

export default function Login() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useNavigate();
    const { login } = useAuth();
    let location = useLocation();
  
    let { from } = location.state || { from: { pathname: "/dashboard" } };
  
  
    async function handleSubmit(e) {
      e.preventDefault();
  
     
  
      try {
        setError("");
        setLoading(true);
  
          await login(emailRef.current.value, passwordRef.current.value);
          console.log("completed login, going to verify")
  
        //               await verifyUser();
        // history.push(from);
        history("/");
      } catch (error) {
        setError(error.message);
      }
  
      setLoading(false);
    }
  return (
    <div className='Background-gradient h-100 p-5'>
        <div className='row m-0 h-100'>
            <div className='col-2 col-md-4 Background-1   d-none d-md-flex m-0 p-0'>
            </div>
            <div className='col col-md-8 '>
            <Container fluid className="my-auto mx-auto text-center ">   
              <Image fluid src={africanimg} className="responsive-img "/>

            <Row className="my-4">
                <Col sx={11} md={9}   className="mx-auto my-auto  container text-start">

                <H4LoginBlue>Log in to your account</H4LoginBlue>
                {error && <Alert variant="danger">{error}</Alert>}
                {loading && <Spinner className='mx-auto'/>}
                <Form onSubmit={handleSubmit} >
               
                  <Form.Group className='my-4'>
                    <Form.Label >Username</Form.Label>
                    <Form.Control
                      className="form-input  p-3 border-0"
                      type="email"
                      placeholder="Enter email"
                      ref={emailRef}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword" className='my-4'>
                  <Form.Label >Password</Form.Label>
                    <Form.Control
                      className="form-input  p-3 border-0"
                      type="password"
                      placeholder="Password"
                      ref={passwordRef}
                      required
                    />
                  </Form.Group>
                  <Row>
                  <Col sm={6} className='col'>
                  <Form.Check 
                    type='checkbox'
                    id='remember'
                    name='remember'
                    value="remember"
                    label={`Remember me `}
                    />
                  </Col>
                  <Col className='text-end'>
                  <ActionLink to="/forgot-password">Forgot Password</ActionLink>

                  </Col>
                  </Row>
                  <div className='text-center my-2'>
                    <ActiveButtonDeepBlue
                        disabled={loading}
                        variant="success"
                        className="primary-button text-center border-0 px-5"
                        type="submit"
                    >
                        Login
                    </ActiveButtonDeepBlue>
                  </div>
                  
                </Form>
                <p className="text-dark p-1 mx-auto d-block text-center">
                Don't have an account yet? <ActionLink to="/signup" className=" "> Sign up</ActionLink>
                </p>
              </Col>
              
            </Row>
          </Container>
            
            
            </div>
        </div>
    </div>
  )
}
