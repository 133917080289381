import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import "./index.css";
import ErrorPage from "./Pages/error-page";
import Login from "./Pages/Auth/login";
import Signup from "./Pages/Auth/Signup";
import Root,{loader as routeLoader} from "./Pages/Dashboard/Root";
import PublicRoute from "./Components/Context/PublicRoute";
import PrivateRoute from "./Components/Context/PrivateRoute";
import { useAuth } from "./Components/Context/AuthContext";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Accounts from "./Pages/Dashboard/Accounts";
import EditProfile from "./Pages/Profile/EditProfile";
import ResetPassword from "./Pages/Profile/ResetPassword";
import Profile from "./Pages/Profile/Profile";
import ProfileLayout from "./Pages/Profile/ProfileLayout";
import OpenProfile from "./Pages/Profile/OpenProfile";
import UserLayOut from "./Components/Layouts/UserLayOut";
import ForgotPassword from "./Pages/Auth/ForgotPassword";

async function Dologout(){
  const {logout} = useAuth()
  await logout();
}
const Myrouter = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute />,
    errorElement:<ErrorPage/>,
    children:[
      {
        path:"",
        element: <Root/>,
        errorElement:<ErrorPage/>,
        children:[
          {
            path:"logout",
            element:<>{()=>Dologout()}</>,
            errorElement:<ErrorPage/>,
          },
          {
            path: "",
            element: <><Profile/></>,
            errorElement:<ErrorPage/>,
          },
          
          {
              path: "dashboard",
              element: <><UserLayOut/></>,
              errorElement:<ErrorPage/>,
              children:[
                {
                  path: "",
                  element: <><Profile/></>,
                  errorElement:<ErrorPage/>,
                },
                {
                  path: "profiles",
                  element: <><ProfileLayout/></>,
                  errorElement:<ErrorPage/>,
                  children:[
                    {
                      path: "",
                      element: <><Profile/></>,
                      errorElement:<ErrorPage/>,
                    },
                    {
                      path: "edit",
                      element: <><EditProfile/></>,
                      errorElement:<ErrorPage/>,
                    },
                    {
                      path: "reset-password",
                      element: <><ResetPassword/></>,
                      errorElement:<ErrorPage/>,
                    }
                  ]
                },
            ]
           },
          ]
        },
      
      
     
    ]
  },
  {
    element: <PublicRoute restricted/>,
    errorElement:<ErrorPage/>,
    children:[
       {
        path:"login",
        element:<><Login/></>,
        errorElement:<ErrorPage/>,
      },
      {
        path:"signup",
        element:<><Signup/></>,
        errorElement:<ErrorPage/>,
      },
      {
        path:"/forgot-password",
        element:<><ForgotPassword/></>,
        errorElement:<ErrorPage/>,

      },
       
    ]
  },
  {
        path:"/profiles/:linkcode",
        element:<><OpenProfile/></>,
        errorElement:<ErrorPage/>,

      },

]);


export default  Myrouter;
