import React from 'react'
import { Col, Container, Image, Nav, Navbar, Row } from 'react-bootstrap'
import { NavLink, Outlet, useNavigate,useNavigation } from 'react-router-dom'
import { LogoBar, Networkstatus, OnlineIcon, Sidebar, UserName,Navli,Navlinks, FreeLink } from '../../Components/Elements/DashboardElemtents'
import Myimage from '../../Extra/images/brightImg.jpg'
import "./Dashboard.css";
import { BsFillCircleFill } from "react-icons/bs";
import { FaChevronRight,FaChevronLeft, FaTelegram } from "react-icons/fa";
import { useAuth } from '../../Components/Context/AuthContext'
import logoimg from "../../Extra/images/150-logo-white.png"
export async function  loader (){
    await setTimeout(function(){
        //code goes here
    
   }, 5000);
   return null;
}

export default function Root() {
    const navigation = useNavigation();
    const {logout,userProfile} = useAuth();

  return (
    <div className=''>
        <Row className='m-0 p-0 '>
            <Sidebar className='col-4 col-md-3 col-lg-2 h-100 p-0 m-0 sidebar d-none d-md-block position-fixed '>
                    <div className='row m-0 p-0'>
                <Nav className='row m-0 p-0'>
                    <Navlinks to={`/dashboard/profiles`} className="p-0">
                        <LogoBar className="col m-0 p-0 text-center">
                            {/* ONAM<span className='text-white'>GH</span>   */}
                        <Image src={logoimg} className=" responsive-image"/>

                        </LogoBar>
                        <div className='text-center'>
                            <Image roundedCircle src={userProfile && userProfile.photoURL} className="imageFrame responsive-image"/>

                        </div>
                        <UserName className='text-center mt-2'>
                        {userProfile && userProfile.lastName}
                        </UserName>
                        <div>
                            <Networkstatus  className='text-center mt-2' >
                                <BsFillCircleFill/> 
                                <span> Online</span> 
                            </Networkstatus>
                        </div>
                    <div>
                    
                    </div>     
                    </Navlinks>
                    </Nav>
                    
                    <Nav className='row m-0 p-0 '>

                    <Navlinks to={`/dashboard/profiles`}>
                            <FaChevronRight/>
                            <span className='px-1'> Profile</span>
                    </Navlinks>
                    <Navlinks to={`/dashboard/profiles/edit`}>
                        <FaChevronRight/>
                        <span className='px-1'> Update Profile</span> 
                    </Navlinks>
                    <Navlinks to={`/dashboard/profiles/reset-password`}>
                        <FaChevronRight/>
                        <span className='px-1'> Reset password</span> 
                    </Navlinks>
                    <FreeLink href={`https://t.me/+O--ctgHffPk1MWQ0`} target="_blank">
                        <FaTelegram/>
                        <span className='px-1'>Join our Telegram group here!</span> 
                    </FreeLink>
                    
                    
                    <Navlinks to={"logout"} onClick={()=>logout()}>
                        <FaChevronRight/>
                        <span className='px-1'> logout</span> 
                    </Navlinks>
                    
                    
                </Nav>
                </div>
            </Sidebar>
            <div className="col-12 col-md-9 col-lg-10 offset-md-3 offset-lg-2"
            //  className={
            //     navigation.state === "loading" ? "loading" : ""
            //   }
            >
            <Outlet />
            </div>
        </Row>
    </div>

  )
}
