export const verifiedEmails=["muniratumusah48@gmail.com",
"amaasah1@gmail.com",
"pleasantsam7@gmail.com",
"lawreafoley08@gmail.com",
"kobinakak@gmail.com",
"asabea.mante12@gmail.com",
"atukumahj23@gmail.com",
"kyeibaffourisabella@gmail.com",
"minam679@gmail.com",
"boapriscilla005@gmail.com",
"diana.boakyewaah7@gmail.com",
"jerrhodya@gmail.com",
"plainplain68@yahoo.com",
"hahiliaibrahim@gmail.com",
"courage.elormagbotse@gmail.com",
"emaliahaizel7@gmail.com",
"snr.akum@gmail.com",
"ritaakosuaofosu@gmail.com",
"hannaharhin70@gmail.com",
"akosuanyarkod@gmail.com",
"omari.margaret@yahoo.com",
"linyeboah123@gmail.com",
"missireneadobeaofosu@gmail.com",
"swuitkandy@gmail.com",
"babafaizaharuna@yahoo.com",
"quickdon556@gmail.com",
"naomimintah73@gmail.com",
"wugaajob@gmail.com",
"idaasa872@gmail.com",
"nanaboatemaah@gmail.com",
"patiencesamd14@gmail.com",
"bellahemans@gmail.com",
"amaaaah1@gmail.com",
"kadimoha20.km@gmail.com",
"maryababila388@gmail.com",
"dianaampofowaa39@gmail.com",
"abdulfatawuheldata@gmail.com",
"sedybabie9@gmail.com",
"celestinagyimah4@gmail.com",
"caridadanafo@gmail.com",
"eblayarmah@gmail.com",
"antwijuliet185@gmail.com",
"julietagyeiwaaboakye@gmail.com",
"agoglomi@yahoo.com",
"ginaamedzro1@gmail.com",
"ceciliaesichibale1@gmail.com",
"boakyenancy7@gmail.com",
"bigjoenana@gmail.com",
"abdulsomed.zakaria@yahoo.com",
"marciasakparib@gmail.com",
"debbiebrowngh1@gmail.com",
"mercyboadi199@gmail.com",
"gertrudefloraaidoo@gmail.com",
"adusolomon120@gmail.com",
"seyemelia1@gmail.com",
"elizabethkorangaddo@gmail.com",
"sackiteyamanda4141@gmail.com",
"amoahannie73@gmail.com",
"akouaregina96@gmail.com",
"albertadaphnehackman@gmail.com",
"baffourawuahanastasia@gmail.com",
"maryattuah20@gmail.com",
"amadawson10@gmail.com",
"apenemannette@gmail.com",
"nanayaaserwaa2012@gmail.com",
"maamekaymes@gmail.com",
"yirenkyichristiana@yahoo.com",
"portiatawiah@gmail.com",
"giftyobuobi999@gmail.com",
"kissiedu.bee534@gmail.com",
"kwamenaberg@gmail.com",
"derbyzerg@gmail.com",
"ibanazir@gmail.com",
"godsondalene@gmail.com",
"lenavulley@gmail.com",
"lornaboadi@gmail.com",
"otema8@gmail.com",
"kassantenimary35@gmail.com",
"giftydzivenu@gmail.com",
"opheliatok2@gmail.com",
"africandy2008@gmail.com",
"shadopappoe@gmail.com",
"haliduabdulhakeem@gmail.com",
"thomasbukari2015@gmail.com",
"amenuveperpetual98@gmail.com",
"estherbadu916@gmail.com",
"comfortakanyim622@gmail.com",
"hannahkissi40@gmail.com",
"stellaasante990@gmail.com",
"hildaemensah@gmail.com",
"okena.joyce87@gmail.com",
"akualove1988@gmail.com",
"gracebobieansah@gmail.com",
"giftysibeko52@gmail.com",
"aganahaggar8@gmail.com",
"nyamewaa59@gmail.com",
"alhassanzaida@gmail.com",
"ansuracheal@gmail.com",
"aassowah87@yahoo.com",
"richsteve11@gmail.com",
"hpabie1212@gmail.com",
"loveacquah1220@gmail.com",
"crossadumasamoahyaw@gmail.com",
"estheraddokena@gmail.com",
"sorbontey@gmail.com",
"ginatk2@gmail.com",
"ellenaboagye25@gmail.com",
"baahrita94@gmail.com",
"shaibu.ar79@gmail.com",
"gladys1sagoe1@gmail.com",
"joyceyidana80@gmail.com",
"grashiano@gmail.com",
"mariamamohammed380@gmail.com",
"abangafrancis44@gmail.com",
"adugyamfimeriam38@gmail.com",
"kathynyarko83@gmail.com",
"fidjansaustinekwadu@gmail.com",
"gbadagohulda@gmail.com",
"vickiyeb@gmail.com",
"clifusheilla@gmail.com",
"rahealpokuaa35@gmail.com",
"npegu15@gmail.com",
"dorakwabi@gmail.com",
"banyendorcas22@gmail.com",
"cninson10@gmail.com",
"elfredalarkai@gmail.com",
"ebo.osei@hotmail.com",
"winifredarhin22@gmail.com",
"sharkclaud2015@gmail.com",
"janetamponsah37@gmail.com",
"patiencekorly02@gmail.com",
"azaasebernard@gmail.com",
"perfectamenuve@gmail.com",
"eunicetuah1994@gmail.com",
"oliviaarthur2@gmail.com",
"charityayine7@gmail.com",
"habibahkar77@gmail.com",
"mlucygad@gmail.com",
"mirekusarahphillips@gmail.com",
"ishazakari@ymail.com",
"teklestanislas@gmail.com",
"jenniferkanzoni9@gmail.com",
"truusdede@gmail.com",
"sarahdadzie05@yahoo.com",
"osmanursula@gmail.com",
"rhodamawah@gmail.com",
"cletuslimeng@gmail.com",
"ireneampomaa2@gmail.com",
"dentehpatrick2@gmail.com",
"agnesdadzie88@gmail.com",
"nanaabenakwakyewaa@gmail.com",
"anitaawudu@gmail.com",
"beatriceaddison037@gmail.com",
"zeinabseidu502@gmail.com",
"ashiagbor27@gmail.com",
"evababie.eshun@aol.com",
"egyanelizabeth21@gmail.com",
"acheampongdorispeasah@gmail.com",
"gideonadquaye@gmail.com",
"oppongagartha101@gmail.com",
"eurekavigbedor@gmail.com",
"skenetiah@gmail.com",
"chrisban2020@gmail.com",
"gnsowah12@gmail.com",
"sedoloj@gmail.com",
"delimagodsway@gmail.com",
"tagoeabigail16@gmail.com",
"akosuaasabea.va@gmail.com",
"mokyaw94@gmail.com",
"clarapoayine@gmail.com",
"christianaatakora@gmail.com",
"bamedofu@gmail.com",
"abynatawiah@gmail.com",
"lottytutu@gmail.com",
"julietkutani@gmail.com",
"titopatience71@gmail.com",
"eshunmagdalene045@gmail.com",
"shiellamensah794@gmail.com",
"anastaciatettey01@gmail.com",
"edithmatey735@gmail.com",
"adwoaruth87@gmail.com",
"derbyterkpetey@gmail.com",
"tawiahpriscy@gmail.com",
"eayebia09@gmail.com",
"keal9191@gmail.com",
"phyllisasamoahf@gmail.com",
"jenniferyenbil@gmail.com",
"edithelsy28@gmail.com",
"mavsamani@gmail.com",
"vincentbless5@gmail.com",
"ggbeku@gmail.com",
"pansy866@gmail.com",
"aphuasarpong4@gmail.com",
"portiaadrah@yahoo.com",
"rhodaoseiwusu23@gmail.com",
"o.nyarkoedna@gmail.com",
"fredamevegbe123@gmail.com",
"naadjowa84@gmail.com",
"benafigust1992@gmail.com",
"kevelyny@gmail.com",
"maame.boakyewaa16@gmail.com",
"maamelinx95@gmail.com",
"lindaakoto96@gmail.com",
"asarejennifer85@gmail.com",
"akotoemaxwell58@gmail.com",
"duncanlady@gmail.com",
"ackahm140@gmail.com",
"perpetual.ldp@gmail.com",
"kemevornuella0@gmail.com",
"obengarthurgodfred@gmail.com",
"blessarthur35@gmail.com",
"dorisofori@gmail.com",
"maryfremahboateng@gmail.com",
"nanaabena2224@gmail.com",
"ivyokyerezoe@gmail.com",
"eamanquanor4@gmail.com",
"saeedzulaiha20@gmail.com",
"frimpsy846@gmail.com",
"ethelbaaba03@gmail.com",
"kenyafrancisca53@gmail.com",
"oforilinda20@gmail.com",
"tubuohmodeste@gmail.com",
"angelicruth19@gmail.com",
"adamsfauzia2018@gmail.com",
"vidas400@gmail.com",
"rachaelgloverasante@gmail.com",
"rdentaah19@gmail.com",
"chemoahevans@gmail.com",
"prosperat79@gmail.com",
"danielsika6@gmail.com",
"acheampongdorispeasah@gmail.com",
"dzasonprecious@gmail.com",
"senita28@icloud.com",
"oforiwaa21@gmail.com",
"awuradwoaasantewaa28@gmail.com",
"aseda17.aa@gmail.com",
"elizabethkorangaddo@gmail.com",
"estonaba@gmail.com",
"achiaa660@gmail.com",
"fhaganbrown@gmail.com",
"dorisoseibonsu.kmc@gmail.com",
"evaamoakoh@gmail.com",
"ruthboateng71@gmail.com",
"jowusu60@gmail.com",
"harrymum88@gmail.com",
"bloxylove@yahoo.com",
"asare.bertram@gmail.com",
"brago386.jb@gmail.com",
"akosualinmarfo@gmail.com",
"dabahprecious@gmail.com",
"senita28@icloud.com",
"zeezynaabo@gmail.com",
"praise.wanluv86@gmail.com",
"praise.wanluv86@gmail.com",
"emeliaobengmframah@gmail.com",
"Liltrudy101@gmail.com",
"sylviaddzigbodi@gmail.com",
"tawiahjosephine215@gmail.com",
"tawiahjosephine215@gmail.com",
"abeikumunko@gmail.com",
"eunicenartey76@gmail.com",
"iddrisumohammed199368@gmail.com",
"anamansarita46@gmail.com",
"sulemanaosman1992@gmail.com",
"akuaafrematurkson@gmail.com",
"ivious@yahoo.com",
"nyaabadesmond776@gmail.com",
"Mensiwah89@gmail.com",
"temahfrancisca0@gmail.com",
"aseye94@gmail.com","Charityobaidoo@gmail","franciswereko2016@gmail.com","sarahkorang27@gmail.com","ronaldodonkor@gmail.com"]
