// import { DropzoneArea } from 'material-ui-dropzone'
import React, { useEffect, useRef, useState } from 'react'
import { Row ,Form, Col, InputGroup, Container, Breadcrumb, ProgressBar, Image, Button,Modal, Alert, Spinner} from 'react-bootstrap'
import { FaCogs,FaCog, FaHammer, FaDoorOpen } from 'react-icons/fa'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../Components/Context/AuthContext'
import { AddressBar, Chartbox, Headbar, Hr, MainDiv, TitleText, WelcomeText } from '../../Components/Elements/DashboardElemtents'
import { ActionLink, ActiveButtonDeepBlue, ActiveButtonGreen, ActiveButtonLightBlue, ActiveButtonRed, H4Light, H4LoginBlue,PrimaryHighlight } from '../../Components/Elements/PagesElement';
import { firestore, storageRef } from '../../firebase'
import Dropzone from 'react-dropzone'
import { BsCloudUpload } from 'react-icons/bs'
import { Viewer, Worker } from '@react-pdf-viewer/core'

// import "./Dashboard.css";

export default function EditProfile() {
  const { userID,updateProfile,currentUser,userProfile,getUser,logout } = useAuth()
  const [data, setData] = useState({img:'',});
  const [mainError, setMainError] = useState();
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(''); // sets Error from operations
  const [formError, setFormError] = useState([]); // check error from forms
  const [success,setSuccess]=useState(false)
  const [loading, setLoading] = useState(false);
  const passwordRef = useRef();
  const NewpasswordRef = useRef();
  const RepeatnewpasswordRef = useRef();
  const [progress, setProgress] = useState(0);
  const [cvprogress, setCVProgress] = useState(0);
  const [img,setImg] =useState(null);
  const [cv,setCV] =useState(null);
  const [imgUrl,setImgUrl] =useState(null);
  const [cvUrl,setCVUrl] =useState(null);


  const history = useNavigate();
  // const { login } = useAuth();
  let location = useLocation();

  let { from } = location.state || { from: { pathname: "/dashboard" } };

  const UpdateData =(item,value)=>{
   
      setData(data => ({...data, 
          [item]:value}))
  
  setFormError(formError => ({...formError, [item]:''}))
}


  const eventHandler = (event) => {
      let val = event.target.value;
      let nam = event.target.name;
      UpdateData(nam,val)
  };


  const findFormErrors = () => {
      const newErrors = {}
      // name errors
      if ( !data.firstName || data.firstName === '' ) newErrors.firstName = 'First name cannot be blank!'
      if ( !data.lastName || data.lastName === '' ) newErrors.lastName = 'Last name cannot be blank!'
      if ( !data.gender || data.gender === '' ) newErrors.gender = 'Gender cannot be blank!'
      if ( !data.country || data.country === '' ) newErrors.country = 'Country cannot be blank!'
      if ( !data.personalSummary || data.personalSummary === '' ) newErrors.personalSummary = 'Personal summary cannot be blank!'
      if ( !data.dob || data.dob === '' ) newErrors.dob = 'Add a valid date!'
      
      return newErrors;
  }

  
  const Submit = async (url=currentUser.photoURL,cv=null)=>{ 
      // Add Product
      // const {error,success} = await AddClass(data,branch);

      console.log("PHOTO   "+url)
      console.log("CV      "+cv)
      try {
            setMainError("");
            setLoading(true);
    
      await updateProfile(data.firstName, url,cv, userID, data.email,data.country,data.firstName,data.lastName,data.otherNames, data.dob, data.contact,data.gender,data.personalSummary)
      
      setSuccess(true);
      setLoading(false);
    } catch (error) {
      //  setError(error.message);
       setMainError(error);
      setLoading(false);


       }

 }

 
const handleChange = (File) => {
  const file = File[0];
  setImg(file);
  setFormError((error) => ({ ...formError,img: "" }));
};

const handleFileChange = (File) => {
  const file = File[0];
  setCV(file);
  setFormError((error) => ({ ...formError, cv: "" }));
};

const uploadFiles = () => {
  // Uploading to firebase storage
  // and updating URLS array for storage in firestore

  const file = img;
  const cvfile = cv;
  console.log(file)

  // references
  const photoUrl = new Promise((resolve, reject) => {
    if (file) {
      var d = new Date();
      var n = d.getTime();
      const filename = n + file.name;
      const storeVideoRef = storageRef.child(`/images/${userID}/${filename}`);

      storeVideoRef.put(file).on(
        "state_changed",
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          setProgress(parseInt(percentage));
        },
        (err) => {
          setError(err);
          reject(err);
        },
        async () => {
          let myphotoUrl = await storeVideoRef.getDownloadURL();
          setImgUrl(myphotoUrl);
          resolve({ photoUrl: myphotoUrl });
        }
      );
    } else {
      resolve({ photoUrl: null });
    }
  });

  const fileUrl = new Promise((resolve, reject) => {
    if (cvfile) {
      var d = new Date();
      var n = d.getTime();
      const filename = n + cvfile.name;

      // references
      const storeVideoRef = storageRef.child(`/cvs/${userID}/${filename}`);

      storeVideoRef.put(cvfile).on(
        "state_changed",
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          setCVProgress(parseInt(percentage));
        },
        (err) => {
          setError(err);
          reject(err);
        },
        async () => {
          let myfileUrl = await storeVideoRef.getDownloadURL();
          setCVUrl(myfileUrl);
          resolve({ fileUrl: myfileUrl });
        }
      );
    } else {
      resolve({ fileUrl: null });
    }
  });

  Promise.all([photoUrl, fileUrl]).then(([photoUrlRes, fileUrlRes]) => {
    Submit(photoUrlRes.photoUrl, fileUrlRes.fileUrl);
  });
};


const Imagefiles=(img)=>(
   
  img.map(file => (
  <li key={file.path}>
    {/* <img src={URL.createObjectURL(file) || currentUser.photoURL}/> */}
    {file.path} - {file.size} bytes
  </li>
))
) 
  
  const Proceed = async (e) => {
      e.preventDefault();
      setLoading(true)
      // console.log("Proceed!")

  
      const newErrors = findFormErrors()
          // Conditional logic:
      if ( Object.keys(newErrors).length > 0 ) {
          // We got errors!

          console.log("We got errors!")
          setFormError(newErrors)
          setLoading(false)

          } else {
          // No errors! Put any logic here for the form submission!

          if(img || cv){
                uploadFiles();
                console.log("We image upload!")

          }else{
                            console.log("We don't image upload!")

            Submit();
          }

              // 

              
              // continue with other rendering
          }
      
      
  };
  // async function handleSubmit(e) {
  //   e.preventDefault();

   

  //   try {
  //     setError("");
  //     setLoading(true);

  //     //   await login(emailRef.current.value, passwordRef.current.value);
  //       console.log("completed login, going to verify")

  //     //               await verifyUser();
  //     history.push(from);
  //   } catch (error) {
  //     setError(error.message);
  //   }

  //   setLoading(false);
  // }


  const ResumeModal=
  <>     
    <Modal show={show} fullscreen  onHide={() => setShow(false)}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    // onHide={setShow(!show)}
    
    centered
    backdrop="static" keyboard={false} >
      <Modal.Header className='election-name-tab' closeButton>
        <Modal.Title className=" fw-bolder">{data && data.firstName} {data && data.lastName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
       
        {/* <embed className="row w-100 h-100" src={data.cv} ></embed> */}
        <object data={data && data.cv} type="application/pdf" width="100%" height="100%">
      <p>Your device can't view the resume now, follow this link to download <a download rel="noopener noreferrer" target="_blank" href={data && data.cv}>Resume</a></p>
  </object>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" className="rounded-pill" onClick={()=>setShow(!show)}>
          Close 
        </Button>
      </Modal.Footer> */}
    </Modal>
  </>;

useEffect(() => {
  const  fetchdata = async()=>{
    setLoading(true)
    const users = firestore.collection("users").doc(userID);
      
       users.get().then((doc) => {
        if (doc.exists) {

          setData({...doc.data()})
          
            console.log("user data: fetched");
            setLoading(false)
  //   , doc.data()
        } else {
          
            throw "No such document!";
            // setError(No such document!)
          }
    }).catch((error) => {
      console.log("Error getting document:", error);
      setLoading(false)
  setError("Error getting document: "+ error)
  })
 }
fetchdata()

}, [userID])
  return (
    <div className='w-100 m-0'>
    <Headbar className='col-12 m-0 align-top sticky-top m-0 p-0'>
    <Link to={"/logout"}  onClick={()=>logout()} className='float-end align-top my-auto'><FaDoorOpen/></Link> 
    <WelcomeText className='align-top'>{data && data.firstName} {data && data.lastName} </WelcomeText>

    
    <Hr></Hr>
    <AddressBar className='p-3'>
        <TitleText className='p-0'>Profile</TitleText>
        <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard" }}>Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard/profiles" }}  >
                Profiles
                </Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard/profiles/edit" }} active >
                Edit
                </Breadcrumb.Item>
            
        </Breadcrumb>
    </AddressBar>
    </Headbar>
    <MainDiv className='row p-3'>
    <div className='text-center my-2'>
                  
                  <ActiveButtonLightBlue as={Link} to="/dashboard/profiles"
                      disabled={loading}
                      variant="success"
                      className="primary-button text-center border-0 m-1 px-5 mx-2"
                      type="button"
                  >
                      Back
                  </ActiveButtonLightBlue>
                  <ActiveButtonDeepBlue
                  as={Link}
                  to="/dashboard/profiles/reset-password"
                      disabled={loading}
                      variant="success"
                      className="primary-button text-center border-0 m-1 px-5 mx-2"
                      type="button"
                  >   Reset Password
                  </ActiveButtonDeepBlue>
              </div>
              {(success) && <Alert className='text-success'>{"Profile Updated Successfully"}</Alert>}
              {(error) && <Alert className='text-danger'>{error}</Alert>}
              {(mainError) && <Alert className='text-success'>{mainError}</Alert>}
              {(Object.values(formError).length !==0) && <Alert >{Object.values(formError).map((value) => (
                                      <li className='text-danger'>{value}</li>
                                    ))}</Alert>}
              {(loading  && !error) && <Spinner className='mx-auto'/>}
              {(!loading && !error) &&
                <div className='col-12 col-md-8 col-lg-7 '>
                    <Form onSubmit={(e)=>Proceed(e)} className="row ">
                    <Form.Group className="row m-2" controlId="validationCustom07">
                      <Form.Label className="">
                      Profile Picture
                      </Form.Label>
                      <Col className="  p-3 border-0  bg-white">
                    <Image src={ img ? URL.createObjectURL(img):"" ||imgUrl ? imgUrl : currentUser.photoURL}/>
                      </Col>
                      <Col className="border-0 col-12 col-md-6 p-2 p-md-auto">
                      {progress !== 0 && <ProgressBar label={parseInt(progress) +'%'} striped now={progress} />}
                      <Dropzone onDrop={acceptedFiles => handleChange(acceptedFiles)}>
                        {({getRootProps, getInputProps,acceptedFiles}) => (
                            <ActiveButtonLightBlue 
                            type="button"
                            className='btn h-auto text-center '  {...getRootProps()}>
                              <input {...getInputProps()} />
                              
                              {Imagefiles(acceptedFiles)}
                              <BsCloudUpload size={"2em"}/>
                              <span> Upload a Photo</span>
                              <br/>
                              <span className='d-none d-md-inline'>Drag 'n' drop some files here, or click to select files</span>

                            </ActiveButtonLightBlue>
                        )}
                      
                      </Dropzone>
                  {error.photoURL && <div className="alert-danger">{error.photoURL} </div>}
                      </Col>
                  </Form.Group>
                      
                      <Form.Group className='my-2 opacity-100'>
                        <Form.Label >First Name</Form.Label>
                        <Form.Control
                          className="form-input  p-3 border-0  bg-white"
                          type="text"
                          placeholder="First Name"
                          name='firstName'
                          value={data && data.firstName}
                          onChange={eventHandler}
                          isInvalid={ !!formError.firstName }
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.firstName}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className='my-2 opacity-100'>
                        <Form.Label >Last Name</Form.Label>
                        <Form.Control
                          className="form-input  p-3 border-0  bg-white"
                          type="text"
                          placeholder="Last Name"
                          name='lastName'
                          value={data && data.lastName}
                          onChange={eventHandler}
                          isInvalid={ !!formError.lastName }
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.lastName}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className='my-2 opacity-100'>
                        <Form.Label >Other Names</Form.Label>
                        <Form.Control
                          className="form-input  p-3 border-0  bg-white"
                          type="text"
                          placeholder="Other Names"
                          name='otherNames'
                          value={data && data.otherNames}
                          onChange={eventHandler}
                          isInvalid={ !!formError.otherNames }
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.otherNames}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className='my-2 opacity-100'>
                        <Form.Label >Date of Birth</Form.Label>
                        <Form.Control
                          className="form-input  p-3 border-0  bg-white"
                          type="date"
                          placeholder="Date oF Birth"
                          name='dob'
                          value={data && data.dob}
                          onChange={eventHandler}
                          isInvalid={ !!formError.dob }
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.dob}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className='my-2 opacity-100'>
                          <Form.Label >
                          Gender
                          </Form.Label>
                          
                          <Form.Control
                          as="select"
                          className="form-input  p-3 border-0  bg-white"
                          name="gender"
                          value={data && data.gender}
                          onChange={eventHandler}
                          isInvalid={ !!formError.gender }
                          >
                          <option value=''>Select Gender</option>
                          <option value='male'>Male </option>
                          <option  value='female'>Female</option>
                          <option  value='other'>Others</option>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                          {formError.gender}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className='my-2 opacity-100'>
                          <Form.Label >
                          Nationality
                          </Form.Label>
                          
                          <Form.Control
                          as="select"
                          className="form-input  p-3 border-0  bg-white"
                          name="country"
                          value={data && data.country}
                          onChange={eventHandler}
                          isInvalid={ !!formError.country }
                          >
                          <option value=''>Select Nationality</option>
                          <option value="afghan">Afghan</option>
                          <option value="albanian">Albanian</option>
                          <option value="algerian">Algerian</option>
                          <option value="american">American</option>
                          <option value="andorran">Andorran</option>
                          <option value="angolan">Angolan</option>
                          <option value="antiguans">Antiguans</option>
                          <option value="argentinean">Argentinean</option>
                          <option value="armenian">Armenian</option>
                          <option value="australian">Australian</option>
                          <option value="austrian">Austrian</option>
                          <option value="azerbaijani">Azerbaijani</option>
                          <option value="bahamian">Bahamian</option>
                          <option value="bahraini">Bahraini</option>
                          <option value="bangladeshi">Bangladeshi</option>
                          <option value="barbadian">Barbadian</option>
                          <option value="barbudans">Barbudans</option>
                          <option value="batswana">Batswana</option>
                          <option value="belarusian">Belarusian</option>
                          <option value="belgian">Belgian</option>
                          <option value="belizean">Belizean</option>
                          <option value="beninese">Beninese</option>
                          <option value="bhutanese">Bhutanese</option>
                          <option value="bolivian">Bolivian</option>
                          <option value="bosnian">Bosnian</option>
                          <option value="brazilian">Brazilian</option>
                          <option value="british">British</option>
                          <option value="bruneian">Bruneian</option>
                          <option value="bulgarian">Bulgarian</option>
                          <option value="burkinabe">Burkinabe</option>
                          <option value="burmese">Burmese</option>
                          <option value="burundian">Burundian</option>
                          <option value="cambodian">Cambodian</option>
                          <option value="cameroonian">Cameroonian</option>
                          <option value="canadian">Canadian</option>
                          <option value="cape verdean">Cape Verdean</option>
                          <option value="central african">Central African</option>
                          <option value="chadian">Chadian</option>
                          <option value="chilean">Chilean</option>
                          <option value="chinese">Chinese</option>
                          <option value="colombian">Colombian</option>
                          <option value="comoran">Comoran</option>
                          <option value="congolese">Congolese</option>
                          <option value="costa rican">Costa Rican</option>
                          <option value="croatian">Croatian</option>
                          <option value="cuban">Cuban</option>
                          <option value="cypriot">Cypriot</option>
                          <option value="czech">Czech</option>
                          <option value="danish">Danish</option>
                          <option value="djibouti">Djibouti</option>
                          <option value="dominican">Dominican</option>
                          <option value="dutch">Dutch</option>
                          <option value="east timorese">East Timorese</option>
                          <option value="ecuadorean">Ecuadorean</option>
                          <option value="egyptian">Egyptian</option>
                          <option value="emirian">Emirian</option>
                          <option value="equatorial guinean">Equatorial Guinean</option>
                          <option value="eritrean">Eritrean</option>
                          <option value="estonian">Estonian</option>
                          <option value="ethiopian">Ethiopian</option>
                          <option value="fijian">Fijian</option>
                          <option value="filipino">Filipino</option>
                          <option value="finnish">Finnish</option>
                          <option value="french">French</option>
                          <option value="gabonese">Gabonese</option>
                          <option value="gambian">Gambian</option>
                          <option value="georgian">Georgian</option>
                          <option value="german">German</option>
                          <option value="ghanaian">Ghanaian</option>
                          <option value="greek">Greek</option>
                          <option value="grenadian">Grenadian</option>
                          <option value="guatemalan">Guatemalan</option>
                          <option value="guinea-bissauan">Guinea-Bissauan</option>
                          <option value="guinean">Guinean</option>
                          <option value="guyanese">Guyanese</option>
                          <option value="haitian">Haitian</option>
                          <option value="herzegovinian">Herzegovinian</option>
                          <option value="honduran">Honduran</option>
                          <option value="hungarian">Hungarian</option>
                          <option value="icelander">Icelander</option>
                          <option value="indian">Indian</option>
                          <option value="indonesian">Indonesian</option>
                          <option value="iranian">Iranian</option>
                          <option value="iraqi">Iraqi</option>
                          <option value="irish">Irish</option>
                          <option value="israeli">Israeli</option>
                          <option value="italian">Italian</option>
                          <option value="ivorian">Ivorian</option>
                          <option value="jamaican">Jamaican</option>
                          <option value="japanese">Japanese</option>
                          <option value="jordanian">Jordanian</option>
                          <option value="kazakhstani">Kazakhstani</option>
                          <option value="kenyan">Kenyan</option>
                          <option value="kittian and nevisian">Kittian and Nevisian</option>
                          <option value="kuwaiti">Kuwaiti</option>
                          <option value="kyrgyz">Kyrgyz</option>
                          <option value="laotian">Laotian</option>
                          <option value="latvian">Latvian</option>
                          <option value="lebanese">Lebanese</option>
                          <option value="liberian">Liberian</option>
                          <option value="libyan">Libyan</option>
                          <option value="liechtensteiner">Liechtensteiner</option>
                          <option value="lithuanian">Lithuanian</option>
                          <option value="luxembourger">Luxembourger</option>
                          <option value="macedonian">Macedonian</option>
                          <option value="malagasy">Malagasy</option>
                          <option value="malawian">Malawian</option>
                          <option value="malaysian">Malaysian</option>
                          <option value="maldivan">Maldivan</option>
                          <option value="malian">Malian</option>
                          <option value="maltese">Maltese</option>
                          <option value="marshallese">Marshallese</option>
                          <option value="mauritanian">Mauritanian</option>
                          <option value="mauritian">Mauritian</option>
                          <option value="mexican">Mexican</option>
                          <option value="micronesian">Micronesian</option>
                          <option value="moldovan">Moldovan</option>
                          <option value="monacan">Monacan</option>
                          <option value="mongolian">Mongolian</option>
                          <option value="moroccan">Moroccan</option>
                          <option value="mosotho">Mosotho</option>
                          <option value="motswana">Motswana</option>
                          <option value="mozambican">Mozambican</option>
                          <option value="namibian">Namibian</option>
                          <option value="nauruan">Nauruan</option>
                          <option value="nepalese">Nepalese</option>
                          <option value="new zealander">New Zealander</option>
                          <option value="ni-vanuatu">Ni-Vanuatu</option>
                          <option value="nicaraguan">Nicaraguan</option>
                          <option value="nigerien">Nigerien</option>
                          <option value="north korean">North Korean</option>
                          <option value="northern irish">Northern Irish</option>
                          <option value="norwegian">Norwegian</option>
                          <option value="omani">Omani</option>
                          <option value="pakistani">Pakistani</option>
                          <option value="palauan">Palauan</option>
                          <option value="panamanian">Panamanian</option>
                          <option value="papua new guinean">Papua New Guinean</option>
                          <option value="paraguayan">Paraguayan</option>
                          <option value="peruvian">Peruvian</option>
                          <option value="polish">Polish</option>
                          <option value="portuguese">Portuguese</option>
                          <option value="qatari">Qatari</option>
                          <option value="romanian">Romanian</option>
                          <option value="russian">Russian</option>
                          <option value="rwandan">Rwandan</option>
                          <option value="saint lucian">Saint Lucian</option>
                          <option value="salvadoran">Salvadoran</option>
                          <option value="samoan">Samoan</option>
                          <option value="san marinese">San Marinese</option>
                          <option value="sao tomean">Sao Tomean</option>
                          <option value="saudi">Saudi</option>
                          <option value="scottish">Scottish</option>
                          <option value="senegalese">Senegalese</option>
                          <option value="serbian">Serbian</option>
                          <option value="seychellois">Seychellois</option>
                          <option value="sierra leonean">Sierra Leonean</option>
                          <option value="singaporean">Singaporean</option>
                          <option value="slovakian">Slovakian</option>
                          <option value="slovenian">Slovenian</option>
                          <option value="solomon islander">Solomon Islander</option>
                          <option value="somali">Somali</option>
                          <option value="south african">South African</option>
                          <option value="south korean">South Korean</option>
                          <option value="spanish">Spanish</option>
                          <option value="sri lankan">Sri Lankan</option>
                          <option value="sudanese">Sudanese</option>
                          <option value="surinamer">Surinamer</option>
                          <option value="swazi">Swazi</option>
                          <option value="swedish">Swedish</option>
                          <option value="swiss">Swiss</option>
                          <option value="syrian">Syrian</option>
                          <option value="taiwanese">Taiwanese</option>
                          <option value="tajik">Tajik</option>
                          <option value="tanzanian">Tanzanian</option>
                          <option value="thai">Thai</option>
                          <option value="togolese">Togolese</option>
                          <option value="tongan">Tongan</option>
                          <option value="trinidadian or tobagonian">Trinidadian or Tobagonian</option>
                          <option value="tunisian">Tunisian</option>
                          <option value="turkish">Turkish</option>
                          <option value="tuvaluan">Tuvaluan</option>
                          <option value="ugandan">Ugandan</option>
                          <option value="ukrainian">Ukrainian</option>
                          <option value="uruguayan">Uruguayan</option>
                          <option value="uzbekistani">Uzbekistani</option>
                          <option value="venezuelan">Venezuelan</option>
                          <option value="vietnamese">Vietnamese</option>
                          <option value="welsh">Welsh</option>
                          <option value="yemenite">Yemenite</option>
                          <option value="zambian">Zambian</option>
                          <option value="zimbabwean">Zimbabwean</option>
                        
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                          {formError.country}
                        </Form.Control.Feedback>
                      </Form.Group>
                      
                    
                    
                      <Form.Group className='col-12 col-md-12 my-2 opacity-100'>
                        <Form.Label >Contact</Form.Label>
                        <Form.Control
                          className="form-input  p-3 border-0  bg-white"
                          type="phone"
                          placeholder="Contact"
                          name='contact'
                          value={data && data.contact}
                          onChange={eventHandler}
                          isInvalid={ !!formError.contact }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.contact}
                        </Form.Control.Feedback>
                      </Form.Group>





                      <Form.Group className='col-12 col-md-12 my-2 opacity-100'>
                        <Form.Label >Email Address </Form.Label>
                        <Form.Control
                          className="form-input  p-3 border-0  bg-white"
                          placeholder="Email Address"
                          type="email"
                          name="email"
                          value={currentUser.email}
                          onChange={eventHandler}
                          disabled
                          isInvalid={ !!formError.email }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Personal Summary</Form.Label>
                        <Form.Control as="textarea" rows={6}
                        name="personalSummary"
                        value={data && data.personalSummary}
                        onChange={eventHandler} />
                        <Form.Control.Feedback type="invalid">
                          {formError.personalSummary}
                        </Form.Control.Feedback>
                      </Form.Group>   
                      <Row>  
                      {/* <Col className="text-md-end col-md-6">
                          <ActiveButtonDeepBlue type='button' onClick={()=>setShow(!show)} className="btn ">Open Your Resume</ActiveButtonDeepBlue>
                      </Col> */}
                  
                  {ResumeModal}            
                        <Col className="border-0 col-12 col-md-6 p-2 p-md-auto">
                        {progress !== 0 && <ProgressBar label={parseInt(cvprogress) +'%'} striped now={cvprogress} />}
                        <Dropzone onDrop={acceptedFiles => handleFileChange(acceptedFiles)} accept={'application/pdf'['.pdf']}>
                          {({getRootProps, getInputProps,acceptedFiles}) => (
                              <ActiveButtonLightBlue 
                              type="button"
                              className='btn h-auto text-center  text-black '  {...getRootProps()}>
                                <input {...getInputProps()} />
                                
                                {Imagefiles(acceptedFiles)}
                                <BsCloudUpload size={"2em"}/>
                                <span className=''> Upload your CV</span> <br/>
                                <span className='text-danger'> Upload only pdf documents here! </span>

                              </ActiveButtonLightBlue>
                          )}
                        
                        </Dropzone>
                        </Col>
                  </Row>
                    
                      {/* <Row>
                        <Form.Group>
                      <Col className=''>
                        <span className="p-2 mx-3">I am a</span>
                      <Form.Check 
                        className='d-inline-flex mx-3 p-2'
                        type='radio'
                        id='remember'
                        name='role'
                        value="supplier"
                        label={ <PrimaryHighlight className='px-2' >Supplier</PrimaryHighlight>}
                        />
                  
                    
                      <Form.Check 
                        className='d-inline-flex mx-3 p-2 '
                        type='radio'
                        id='remember'
                        name='role'
                        value="secretariat"
                        label={ <PrimaryHighlight className='px-2' >Secretariat</PrimaryHighlight>}
                        />
                  
                      <Form.Check 
                        className='d-inline-flex mx-3 p-2'
                        type='radio'
                        id='remember'
                        name='role'
                        value="facility_user"
                        label={ <PrimaryHighlight className='px-2' >Facility User</PrimaryHighlight>}
                        />
                      </Col>
                      </Form.Group> */}
                      {/* <Col className='text-end'>
                      <ActionLink to="/reset-password">Forgot Password</ActionLink>

                      </Col>
                      </Row> */}
                      <div className='text-center my-2'>
                        <ActiveButtonGreen 
                            disabled={loading}
                            variant="success"
                            className="primary-button text-center border-0 px-5 m-2"
                            type="submit"
                            onClick={(e)=>Proceed(e)}
                        >
                            Save
                        </ActiveButtonGreen>
                        <ActiveButtonRed as={Link} to="/dashboard/profiles"
                            disabled={loading}
                            variant="success"
                            className="primary-button text-center border-0 px-5 m-2"
                            type="button"
                        >
                            Cancel
                        </ActiveButtonRed>
                      </div>
                      
                    </Form>
                </div>
                }
          
    </MainDiv>
  </div>
  )
}
