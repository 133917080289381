// import { DropzoneArea } from 'material-ui-dropzone'
import React, { useEffect, useRef, useState } from 'react'
import { Row ,Form, Col, InputGroup, Container, Breadcrumb, ProgressBar, Image, Modal, Spinner, Alert} from 'react-bootstrap'
import { FaCogs,FaCog, FaHammer } from 'react-icons/fa'
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../Components/Context/AuthContext'
import { AddressBar, Chartbox, Headbar, Hr, MainDiv, TitleText, WelcomeText } from '../../Components/Elements/DashboardElemtents'
import { ActionLink, ActiveButtonDeepBlue, ActiveButtonGreen, ActiveButtonLightBlue, ActiveButtonRed, H4Light, H4LoginBlue,PrimaryHighlight } from '../../Components/Elements/PagesElement';
import { firestore, storageRef } from '../../firebase'
// import Myimage from "../../../assets/img/logo.png"

// import "./Dashboard.css";

export default function Profile() {
  const [mainError, setMainError] = useState('');
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(''); // sets Error from operations
  const [formError, setFormError] = useState([]); // check error from forms
  const [success,setSuccess]=useState(false)
  const [show,setShow]=useState(false)
  const [loading, setLoading] = useState(false);
  
  const [userProfile,setUserProfile] =useState(null);
  const {linkcode} = useParams();

  const history = useNavigate();
  let location = useLocation();

  let { from } = location.state || { from: { pathname: "/dashboard" } };

  if (linkcode) {
      console.log(linkcode + ' --the code is set')
  }


   useEffect(() => {
    setLoading(true)
      const users = firestore.collection("users").doc(linkcode);
        
         users.get().then((doc) => {
          if (doc.exists) {

            setUserProfile(doc.data())
            
              console.log("user data: fetched");
              setLoading(false)
    //   , doc.data()
          } else {
            
              throw "No such document!";
              // setError(No such document!)
            }
      }).catch((error) => {
        console.log("Error getting document:", error);
        setLoading(false)
    setError("Error getting document: "+ error)
    })

  }, [linkcode])
  
  const ResumeModal=
  <>     
    <Modal show={show} fullscreen  onHide={() => setShow(false)}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    // onHide={setShow(!show)}
    
    centered
    backdrop="static" keyboard={false} >
      <Modal.Header className='election-name-tab' closeButton>
        <Modal.Title className=" fw-bolder">{userProfile && userProfile.firstName} {userProfile && userProfile.lastName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
       
      <object data={userProfile && userProfile.cv} type="application/pdf" width="100%" height="100%">
          <p>Your device can't view the resume now, follow this link to download <a download rel="noopener noreferrer" target="_blank" href={userProfile && userProfile.cv}>Resume</a></p>
      </object>
        </Modal.Body>
     
    </Modal>
  </>;


  return (
    <div className='w-100 m-0 bg-white'>
    <Headbar className='col-12 m-0 align-top sticky-top m-0 p-0'>
    <WelcomeText className='align-top'>{userProfile && userProfile.firstName} {userProfile && userProfile.lastName}</WelcomeText>
    
    <Hr></Hr>
    </Headbar>
    {(error) && <Alert >{error}</Alert>}
    {(loading && !error) && <Spinner className='mx-auto'/>}
    {(!loading && !error) &&
    <MainDiv className='row p-3'>
                <div className='col-12 col-md-10 col-lg-10 mx-auto '>
                  <Form  className="row ">
                  <Col className=" m-2" controlId="validationCustom07">
                    
                    <Col className="p-3 border-0  bg-gradient">
                    <div className=''>
                            <Image rounded src={userProfile && userProfile.photoURL } className="mx-auto text-center  responsive-image img"/>

                        </div>
                    </Col>
                </Col>
                <Col >
                    
                    
                    <WelcomeText> {userProfile && userProfile.firstName} {userProfile && userProfile.lastName} </WelcomeText>
                    <p className='my-2 opacity-100 lead'>
                     {userProfile && userProfile.personalSummary}
                    </p>
                    <Form.Group className='my-2 opacity-100'>
                      <Form.Label >Date of Birth</Form.Label>
                    : {userProfile && userProfile.dob}
                    </Form.Group>
                    <Form.Group className='my-2 opacity-100'>
                        <Form.Label >
                        Gender
                        </Form.Label>
                        
                      : {userProfile && userProfile.gender}
                    </Form.Group>
                    <Form.Group className='my-2 opacity-100'>
                        <Form.Label >
                        Country
                        </Form.Label>
                        
                    : {userProfile && userProfile.country}
                    </Form.Group>
                    
                  
                   
                    <Form.Group className='col-12 col-md-12 my-2 opacity-100'>
                      <Form.Label >Telephone</Form.Label>
                    : {userProfile && userProfile.contact}
                    </Form.Group>





                    <Form.Group className='col-12 col-md-12 my-2 opacity-100'>
                      <Form.Label >Email Address </Form.Label>
                    : {userProfile && userProfile.email}
                    </Form.Group>   
                    {ResumeModal}            
              
                    <Form.Group className='col-12 col-md-12 my-2 opacity-100'>
                    <Col className="text-end col-md-6">
                        <ActiveButtonDeepBlue type='button' onClick={()=>setShow(!show)} className="btn ">Open Your Resume</ActiveButtonDeepBlue>
                    </Col>
                    </Form.Group>                 
                    </Col>

                    
                  
                   
                    
                  </Form>
              </div>
          
    </MainDiv>}
  </div>
  )
}
