// import { DropzoneArea } from 'material-ui-dropzone'
import React, { useEffect, useRef, useState } from 'react'
import { Row ,Form, Col, InputGroup, Container, Breadcrumb, ProgressBar, Image, Modal,Toast, Alert, Spinner} from 'react-bootstrap'
import { FaCogs,FaCog, FaHammer, FaTelegram, FaDoorOpen } from 'react-icons/fa'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../Components/Context/AuthContext'
import { AddressBar, Chartbox, Headbar, Hr, MainDiv, TitleText, WelcomeText } from '../../Components/Elements/DashboardElemtents'
import { ActionLink, ActiveButtonDeepBlue, ActiveButtonGreen, ActiveButtonLightBlue, ActiveButtonRed, H4Light, H4LoginBlue,PrimaryHighlight } from '../../Components/Elements/PagesElement';
import { firestore, storageRef } from '../../firebase'
// import Myimage from "../../../assets/img/logo.png"

import "../../index.css";

export default function Profile() {
  const { userID,currentUser , logout} = useAuth()
  const [mainError, setMainError] = useState('');
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(''); // sets Error from operations
  const [formError, setFormError] = useState([]); // check error from forms
  const [success,setSuccess]=useState(false)
  const [show,setShow]=useState(false)
  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [toast, setToast] = useState(false);
  const [msg,setMsg] =useState(null);


  const history = useNavigate();
  // const { login } = useAuth();
  let location = useLocation();

  let { from } = location.state || { from: { pathname: "/dashboard" } };


  const ResumeModal=
  <>     
    <Modal show={show} fullscreen  onHide={() => setShow(false)}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    // onHide={setShow(!show)}
    
    centered
    backdrop="static" keyboard={false} >
      <Modal.Header className='election-name-tab' closeButton>
        <Modal.Title className=" fw-bolder">{userProfile && userProfile.firstName} {userProfile && userProfile.lastName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
       
        <object data={userProfile && userProfile.cv} type="application/pdf" width="100%" height="100%">
      <p>Your device can't view the resume now, follow this link to download <a download rel="noopener noreferrer" target="_blank" href={userProfile && userProfile.cv}>Resume</a></p>
  </object>
      </Modal.Body>
     
    </Modal>
  </>;

const TforToast=   
        <Toast position="top-end"
        aria-live="polite"
        aria-atomic="true"
        className="mytoasts"
        // style={{ minHeight: '240px' }}
        onClose={() => setToast(false)} show={toast} delay={3000} autohide>
            <Toast.Header>
            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
            <strong className="me-auto">Alert</strong>
            <small className="text-muted">just now</small>
            </Toast.Header>
            <Toast.Body>{msg}</Toast.Body>
        </Toast>

function copyRegister() {
  const el = document.createElement('input');
  el.value =`https://member.onamgh.org/profiles/${userProfile && userProfile.id}`;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  setMsg("Link Coppied Successfully");
  setToast(true)
}
useEffect(() => {
  const  fetchdata = async()=>{
    setLoading(true)
    const users = firestore.collection("users").doc(userID);
      
       users.get().then((doc) => {
        if (doc.exists) {

          setUserProfile({...doc.data()})
          
            console.log("user data: fetched");
            setLoading(false)
  //   , doc.data()
        } else {
          
            throw "No such document!";
            // setError(No such document!)
          }
    }).catch((error) => {
      // console.log("Error getting document:", error);
      setLoading(false)
  setError("Error getting document: "+ error)
  })
 }
 fetchdata()

}, [userID])

  return (
    <div className='w-100 m-0 bg-white'>
    <Headbar className='col-12 m-0 align-top sticky-top m-0 p-0'>
    <Link to={"/logout"} onClick={()=>logout()}  className='float-end align-top my-auto'><FaDoorOpen/></Link> 
    <WelcomeText className='align-top'>{userProfile && userProfile.firstName} {userProfile && userProfile.lastName}</WelcomeText>

    
    <Hr></Hr>
    <AddressBar className='p-3'>
        <TitleText className='p-0'>Profile</TitleText>
        <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard" }}>Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/profiles" }} active >
                Profiles
                </Breadcrumb.Item>
            
        </Breadcrumb>
    </AddressBar>
    </Headbar>
    <MainDiv className='row p-3 bg-white'>
    <a href={`https://t.me/+O--ctgHffPk1MWQ0`} target="_blank">
                        <FaTelegram/>
                        <span className='px-1'>Join our Telegram group here!</span> 
                    </a>
    {TforToast}

    <div className='text-center my-2'>
                  
                  <ActiveButtonLightBlue as={Link} to="/dashboard/profiles/edit"
                      disabled={loading}
                      variant="success"
                      className="primary-button text-center border-0 px-5 mx-2 p-1 m-1"
                      type="button"
                  >
                      Edit Profile
                  </ActiveButtonLightBlue>
                  <ActiveButtonDeepBlue
                  as={Link}
                  to="/dashboard/profiles/reset-password"
                      disabled={loading}
                      variant="success"
                      className="primary-button text-center border-0 px-5 mx-2 p-1 m-1"
                      type="button"
                  >   Reset Password
                  </ActiveButtonDeepBlue>
                </div>
                {(error) && <Alert className='mx-auto' >{error}</Alert>}
              {(loading && !error) && <Spinner className='mx-auto'/>}
              {(!loading && !error) &&
              <div className='col-12 col-md-10 col-lg-10 mx-auto '>
                  <Form  className="row ">
                  <Col className=" m-2" controlId="validationCustom07">
                    
                    <Col className="p-3 border-0  bg-gradient">
                    <div className=''>
                            <Image rounded src={currentUser && currentUser.photoURL } className="mx-auto text-center  responsive-image img"/>

                        </div>
                    </Col>
                </Col>
                <Col >
                    
                    
                    <WelcomeText> {userProfile && userProfile.firstName} {userProfile && userProfile.lastName} </WelcomeText>
                    <p className='my-2 opacity-100 lead'>
                     {userProfile && userProfile.personalSummary}
                    </p>
                    
                    <Form.Group className='my-2 opacity-100 '>
                      <Form.Label >Unique Link</Form.Label>
                    : <div className='overflow-auto'>{userProfile && `https://member.onamgh.org/profiles/${userProfile.id}`}</div> 
                    <ActiveButtonLightBlue type='button' className="btn m-1" onClick={() => copyRegister()}>{"Copy url"}</ActiveButtonLightBlue>
                    </Form.Group>
                    <Form.Group className='my-2 opacity-100'>
                      <Form.Label >Date of Birth</Form.Label>
                    : {userProfile && userProfile.dob}
                    </Form.Group>
                    <Form.Group className='my-2 opacity-100'>
                        <Form.Label >
                        Gender
                        </Form.Label>
                        
                      : {userProfile && userProfile.gender}
                    </Form.Group>
                    <Form.Group className='my-2 opacity-100'>
                        <Form.Label >
                        Country
                        </Form.Label>
                        
                    : {userProfile && userProfile.country}
                    </Form.Group>
                    
                  
                   
                    <Form.Group className='col-12 col-md-12 my-2 opacity-100'>
                      <Form.Label >Telephone</Form.Label>
                    : {userProfile && userProfile.contact}
                    </Form.Group>





                    <Form.Group className='col-12 col-md-12 my-2 opacity-100'>
                      <Form.Label >Email Address </Form.Label>
                    : {userProfile && userProfile.email}
                    </Form.Group>   
                    {ResumeModal}            
                    <Form.Group className='col-12 col-md-12 my-2 opacity-100'>
                    <Form.Label >Resume</Form.Label> 
                      {userProfile && 
                    (userProfile.cv) ?
                    
                        <ActiveButtonDeepBlue type='button' onClick={()=>setShow(!show)} className="btn mx-2">Open Your Resume</ActiveButtonDeepBlue>
                    :
                    "CV not uploaded yet"}  
                    </Form.Group>               
                    </Col>

                    
                  
                   
                    
                  </Form>
              </div>
              }
          
    </MainDiv>
  </div>
  )
}
