import React,{useRef,useState} from 'react'
import { Row,Container,Form ,Alert,Col,Button, Image} from 'react-bootstrap'
import { Link, useLocation, useNavigate,useNavigation } from 'react-router-dom';
import { ActionLink, ActiveButtonDeepBlue, H4LoginBlue } from '../../Components/Elements/PagesElement';
import "./auth-pages.css";
import africanimg from "../../Extra/images/150-logo-black.png"
import { useAuth } from '../../Components/Context/AuthContext';

export default function ForgotPassword() {
    const emailRef = useRef();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useNavigate();
    const { resetPassword } = useAuth();
    let location = useLocation();
  
    let { from } = location.state || { from: { pathname: "/dashboard" } };
  
  
    async function handleSubmit(e) {
      e.preventDefault();
  
     
  
      try {
        setError("");
        setLoading(true);
  
          await resetPassword(emailRef.current.value);
          console.log("completed login, going to verify")
  
        //               await verifyUser();
        // history.push(from);
        history("/");
      } catch (error) {
        setError(error.message);
      }
  
      setLoading(false);
    }
  return (
    <div>
        <div className='row m-0 '>
            <div className='col-2 col-md-4 Background-1  d-none d-md-flex m-0 p-0'>
            </div>
            <div className='col col-md-8 '>
            <Container fluid className="my-auto mx-auto text-center">   
              <Image fluid src={africanimg} className="responsive-img "/>

            <Row className="height-full-S my-5">
                <Col sx={11} md={9}   className="mx-auto my-auto  container text-start">

                <H4LoginBlue>Reset your password</H4LoginBlue>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit} >
               
                  <Form.Group className='my-4'>
                    <Form.Label >Enter your email here</Form.Label>
                    <Form.Control
                      className="form-input  p-3 border-0"
                      type="email"
                      placeholder="Enter email"
                      ref={emailRef}
                      required
                    />
                  </Form.Group>

                  
                  <div className='text-center my-5'>
                    <ActiveButtonDeepBlue
                        disabled={loading}
                        variant="success"
                        className="primary-button text-center border-0 px-5"
                        type="Reset Password"
                    >
                        Login
                    </ActiveButtonDeepBlue>
                  </div>
                  
                </Form>
                <p className="text-muted p-1 mx-auto d-block text-center">
               A password reset link will be sent to the email you provided? 
                </p>
              </Col>
              
            </Row>
          </Container>
            
            
            </div>
        </div>
    </div>
  )
}
