import React, { Fragment,Suspense } from 'react'
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import "./index.css";
import './App.css';
import Myrouter from './Myrouter';
import { AuthProvider } from './Components/Context/AuthContext';
import { Spinner,Col, Row, Image } from 'react-bootstrap';
import africanimg from "./Extra/images/150-logo-black.png"

function Preloader() {
  return (
    <Row className="preloader h-100 mx-auto my-auto p-auto text-center">
      <Image fluid src={africanimg} className="responsive-img my-5 mx-auto opacity-2"/>

      <Spinner className='mx-auto text-dark'/>
      <p className='lead text-dark'>Loading...</p>
    </Row>
  );
}

function App() {
  return (
      <Fragment >
        <AuthProvider>
        <Suspense fallback={<Preloader />}>


        <RouterProvider router={Myrouter}/>
        </Suspense>
        </AuthProvider> 

      </Fragment>
  );
}

export default App;

