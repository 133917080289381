import React,{useRef,useState} from 'react'
import { Row,Container,Form ,Alert,Col,Button, Spinner} from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ActionLink, ActiveButtonDeepBlue, H4Light, H4LoginBlue,PrimaryHighlight } from '../../Components/Elements/PagesElement';
import { useAuth } from '../../Components/Context/AuthContext';
import "./auth-pages.css";
import africanimg from "../../Extra/images/logo-2.png"

export default function Signup() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const passwordConfirmRef = useRef();
  const tanddRef = useRef();
   
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useNavigate();
    // const { login } = useAuth();
    let location = useLocation();
    const {signup, verifyUser, login,ufrom } = useAuth();
  
    let { from } = location.state || (ufrom && { from: { pathname: ufrom } })|| { from: { pathname: "/login" } };
    
 
   async function handleSubmit(e) {
     e.preventDefault();
     setLoading(true);

 
     if (passwordRef.current.value !== passwordConfirmRef.current.value) {
           setLoading(false);
          return setError("Passwords do not match");

     }
 
     try {
       setError("");
       setLoading(true);
       await signup(
         emailRef.current.value,
         passwordRef.current.value,
         firstNameRef.current.value,
         lastNameRef.current.value
       );
       // console.log("completed signup, going to login")
       // await login(emailRef.current.value, passwordRef.current.value);
       // console.log("completed login, going to verify")
 
       await verifyUser();
       history.push(from);
     } catch (error) {
       setError(error.message);
     }
 
     setLoading(false);
   }
  return (
    <div>
        <div className='row m-0 h-100'>
            <div className='row m-0 p-0 Background-2'>

            
              <div className='row m-0 p-0  Background-gradient '>
              <div className='col-3   col-md-1 d-none d-md-flex m-0 p-0'>
              </div>
                 

              <Col fluid className="my-auto mx-auto col-12 col-md-8 text-center">
              <img src={africanimg} className="h-md-50 responsive-img my-auto mx-auto text-center"/>

              <Row className="m-lg-5 p-lg-5 p-md-auto m-md-auto">
              <H4Light className='px-5'>Sign Up</H4Light>

                <Col className="mx-auto my-auto   py-md-3 px-md-5 border-0 transparent-filter container text-start">
                  {error && <Alert variant="danger">{error}</Alert>}
                  {loading && <Spinner className='mx-auto'/>}

                  <Form onSubmit={handleSubmit} >
                
                    <Form.Group className='my-4 opacity-100'>
                      <Form.Label >First Name</Form.Label>
                      <Form.Control
                        className="form-input  p-3 border-0  bg-white"
                        type="text"
                        placeholder="Enter First Name"
                        ref={firstNameRef}
                        required
                      />
                    </Form.Group>
                    <Form.Group className='my-4 opacity-100'>
                      <Form.Label >Last Name</Form.Label>
                      <Form.Control
                        className="form-input  p-3 border-0  bg-white"
                        type="text"
                        placeholder="Enter Last Name"
                        ref={lastNameRef}
                        required
                      />
                    </Form.Group>
                    <Form.Group className='my-4'>
                      <Form.Label >Email</Form.Label>
                      <Form.Control
                        className="form-input  p-3 border-0"
                        type="email"
                        placeholder="Enter email"
                        ref={emailRef}
                        required
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword" className='my-4'>
                    <Form.Label >Password</Form.Label>
                      <Form.Control
                        className="form-input  p-3 border-0"
                        type="password"
                        placeholder="Password"
                        ref={passwordRef}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword" className='my-4'>
                    <Form.Label >Repeat Password</Form.Label>
                      <Form.Control
                        className="form-input  p-3 border-0"
                        type="password"
                        placeholder="Repeat Password"
                        ref={passwordConfirmRef}
                        required
                      />
                    </Form.Group>
                    
                    <Row>
                      <a href='#'>Terms and conditions</a>
                      <Form.Group>
                    <Col className=''>
                    <Form.Check 
                      className='d-inline-flex mx-3 p-2'
                      type='checkbox'
                      id='remember'
                      name='role'
                      value="tandd"
                      ref={tanddRef}

                      label={ <PrimaryHighlight className='px-2' >I accept the terms and conditions</PrimaryHighlight>}
                      />
                
                    </Col>
                    </Form.Group>
                    {/* <Col className='text-end'>
                    <ActionLink to="/reset-password">Forgot Password</ActionLink>

                    </Col> */}
                    </Row>
                    <div className='text-center my-2'>
                      <ActiveButtonDeepBlue
                          disabled={loading}
                          variant="success"
                          className="primary-button text-center border-0 px-5"
                          type="submit"
                      >
                          Sign Up
                      </ActiveButtonDeepBlue>
                    </div>
                    
                  </Form>
                  <p className="text-muted p-1 mx-auto d-block text-center">
                  Already have an account? <ActionLink to="/login" className=" "> Sign in</ActionLink>
                  </p>
                </Col>
                
              </Row>
            </Col>
              
              </div>
            </div>
        </div>
    </div>
  )
}
